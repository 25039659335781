
import axios from '@/utils/axios';
const baseURL = '/api'

// 社区 小区 列表
export const levelList = (params) => {
  return axios({
    baseURL,
    url: '/app/open/dept/levelList',
    method: 'get',
    params
  });
}; 
// 注册 接口
export const registerFn = (data) => {
  return axios({
    baseURL,
    url: '',
    method: 'post',
    data
  });
};

// 字典接口
export const getDictionary = (code) => {
  return axios({
    baseURL,
    url: '/blade-system/dict/dictionary?code=' + code,
    method: 'get',
  });
};
