<template>
  <div class="main">
    <van-form
      validate-first
      class="form"
      @submit="appointment"
    >
      <!-- 姓名 -->
      <van-field
        v-model="visitor.name"
        placeholder="到访人姓名"
        :rules="[{ required: true}]"
      >
        <van-icon slot="left-icon" class="field-left-icon" name="user-o" />
        <span slot="label" class="field-label">到访人姓名</span>
      </van-field>
      <!-- 电话 -->
      <van-field
        v-model="visitor.phone"
        placeholder="到访人电话"
        :rules="[{ required: true}]"
      >
        <van-icon slot="left-icon" class="field-left-icon" name="phone-o" />
        <span slot="label" class="field-label">到访人电话</span>
      </van-field>
      <!-- 到访地址 -->
      <van-field
        readonly
        clickable
        label="社区"
        :value="visitor.shequName"
        placeholder="请选择社区"
        @click="showPicker1 = true"
        :rules="[{ required: true}]"
        >
          <van-icon slot="left-icon" class="field-left-icon" name="wap-home-o" />
          <span slot="label" class="field-label">到访社区</span>
      </van-field>
      <van-popup v-model="showPicker1" round position="bottom">
        <van-picker
            show-toolbar
            value-key="deptName"
            :columns="shequList"
            @cancel="showPicker1 = false"
            @confirm="onConfirm1"
        />
      </van-popup>
      <van-field
        readonly
        clickable
        label="小区"
        :value="visitor.xiaoquName"
        placeholder="请选择小区"
        @click="showPicker2 = true"
        :rules="[{ required: true}]"
      >
        <van-icon slot="left-icon" class="field-left-icon" name="wap-home-o" />
        <span slot="label" class="field-label">到访小区</span>
      </van-field>
      <van-popup v-model="showPicker2" round position="bottom">
        <van-picker
          show-toolbar
          value-key="deptName"
          :columns="xiaoquList"
          @cancel="showPicker2 = false"
          @confirm="onConfirm2"
        />
      </van-popup>
      <van-field
        v-model="fieldValue"
        is-link
        readonly
        placeholder="请选择楼宇、单元、房间"
        :rules="[{ required: true}]"
        @click="showFieldValue = true"
      >
        <van-icon slot="left-icon" class="field-left-icon" name="newspaper-o" />
        <span slot="label" class="field-label">到访房间</span>
      </van-field>
      <van-popup v-model="showFieldValue" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择楼宇、单元、房间"
          :options="options"
          @close="showFieldValue = false"
          @finish="onFieldValue"
        />
      </van-popup>
      
      <!-- 时间  -->
      <van-field
        readonly
        clickable
        name="datetimePicker"
        :value="visitor.visitorTime"
        placeholder="预约时间"
        right-icon="arrow"
        :rules="[{ required: true}]"
        @click="chooseCome">
        <van-icon slot="left-icon" class="field-left-icon" name="clock-o" />
        <span slot="label" class="field-label">到访时间</span>
      </van-field>
      <van-popup
        v-model="showVisitTimePicker"
        position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="选择时间"
          :columns-order="['year', 'month', 'day', 'hour', 'minute']"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onConfirmVisitTime"
          @cancel="showVisitTimePicker = false" />
      </van-popup>
      <!-- 人脸照片 -->
      <h2 class="category-title-label"><van-icon slot="left-icon" class="field-left-icon" name="comment-o" />人脸图片</h2>
      <van-field>
        <template #input>
          <van-uploader @oversize="oversize" v-model="visitor.codeUrl" :max-size="5 * 1024 * 1024" image-fit="fill" max-count="1" :after-read="uploadPictures" />
        </template>
      </van-field>
      <!-- 备注 -->
      <h2 class="category-title-label"><van-icon slot="left-icon" class="field-left-icon" name="clock-o" />备注</h2>
      <van-field
        v-model="visitor.remark"
        rows="2"
        autosize
        type="textarea"
        maxlength="50"
        style="color:red"
        show-word-limit>
      </van-field>

      
      <div style="margin: 0.375rem;">
        <van-button
          round
          block
          class="submit"
          native-type="submit"
        >
          确定预约
        </van-button>
      </div>
    </van-form>
  </div>
  
</template>

<script>
import { levelList as levelListTree } from '@/api/repairApi'
import { levelList } from "@/api/registerApi"
import axios from '@/utils/axios';
import { visitorSave } from '@/api/visitorApi.js'
import { dateFormat  } from "@/utils/date"

export default {
  data() {
    return {
      visitor: {
        faceUrl: '',
        houseId: ''
      },
      showVisitTimePicker: false,
      minDate: new Date(),
      maxDate: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      currentDate: dateFormat(new Date(), 'YYYY/MM/DD HH:II'),
      showFieldValue: false,//小区、楼宇、单元、房间
      fieldValue: '',
      cascaderValue: '',
      options:[],
      showPicker1: false,
      showPicker2: false,
      shequList: [],
      xiaoquList: [],
    }
  },
  created() {
    this.getLevelList()
  },
  methods: {
    // 图片超出大小
    oversize() {
      this.$toast({
        type: 'warning',
        message: '图片不能超过5M~',
        duration: 500,
      })
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      } else if (type === 'hour') {
        return `${val}时`;
      } else if (type === 'minute') {
        return `${val}分`;
      }
      return val;
    },
    // 提交表单回调
    appointment() {
      if (this.visitor.faceUrl === '') {
        this.$toast({
            type: 'fail',
            message: '请上传人脸照片',
            duration: 1500
        })
        return false
      }
      this.visitor.houseId = this.cascaderValue
      this.visitor.openid = this.$store.getters.openId,
      visitorSave(this.visitor).then((res) => {
        if(res.code == 200) {
          this.$toast({
            type: 'success',
            message: '预约成功',
            duration: 1500,
            onClose: () => {
              this.$router.go(-1)
            }
          })
        } else {
          this.$toast({
          type: 'error',
          message: res.message,
          duration: 1500
          })
        }
      })
    },
    // 点击 访问时间
    chooseCome() {
        this.minDate = new Date()
        this.showVisitTimePicker = true;
    },
    //  预约时间 选择器
    onConfirmVisitTime(time) {
        this.visitor.visitorTime = dateFormat(time, "YYYY-MM-DD HH:II:SS")
        this.showVisitTimePicker = false;
    },
    // 处理到访房间信息
    onFieldValue({ selectedOptions }) {    
      this.showFieldValue = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('');
      let fieldValues = selectedOptions.map((option) => option.value);
      if(fieldValues.length < 3) {
        return this.$toast({
            type: 'warning',
            message: '楼宇、单元、房间信息不全',
            duration: 1000,
          })
      }
    },
    // 通过小区获取楼宇、单元、房间等信息
    getbuild(id){
      levelListTree({communityId:id}).then(res => {
        this.options = res.data[0].children
        this.rewriteKey(this.options)
      })
    },
    // 替换名称
    rewriteKey (val) {
      val.forEach(item => {
        item.text = item.title
        if (item.children) {
          this.rewriteKey(item.children)
        }
      })
    },
    getLevelList(parentId) {
      levelList({parentId: parentId}).then(res => {
        if (res.code === 200) {
            if (parentId) {
                this.xiaoquList = res.data
                this.visitor.deptId = ''
                this.visitor.xiaoquName = ''
            } else {
                this.shequList = res.data 
            }
        }
      }) 
    },
    onConfirm1(val) { 
      this.visitor.shequId = val.id
      this.visitor.shequName = val.deptName
      this.visitor.tenantId = val.tenantId
      this.getLevelList(val.id)
      this.showPicker1 = false
      this.fieldValue = ''

    },
    onConfirm2(val) { 
      this.visitor.deptId = val.id
      this.visitor.xiaoquName = val.deptName
      this.getbuild(val.id)
      this.showPicker2 = false
      this.fieldValue = ''
    }, 
    // 图片上传后，获取返回的参数，在提交时当做参数传递给后台，uploadImgList是图片上传后返回的图				片信息列表
    uploadPictures(file) {
      // 上传图
      file.status = 'uploading'
      file.message = '上传中'
      
      let url = file.file
      let formdata = new FormData()
      formdata.append('file', url)

      axios({
        baseURL: '/minio',
        url: '/minio/villageUpload?keepFileName=1&bucketName=village',
        method: 'post',
        data: formdata
      }).then(res => {
        if(res.code == 200) {
          file.url = res.data.url
          this.form.picUrl.push(res.data.url)
          file.status = 'done'
          file.message = ''
        } else {
          file.status = 'failed'
          file.message = '上传失败'
        }
      }).catch(() => {
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    // 上传图片接口
    // upload(file) {
    //   return new Promise((resolve, reject) => {
    //     let formdata = new FormData()
    //     formdata.append('file', file)

    //     axios({
    //       baseURL: '/minio',
    //       url: '/minio/villageUpload?keepFileName=1&bucketName=village',
    //       method: 'post',
    //       data: formdata
    //     }).then(res => {
    //       if(res.code == 200) {
    //         resolve(res)
    //       } else {
    //         reject(res)
    //       }
    //     })
    //   })
    // },
  }
}
</script>

<style lang="scss" scoped>
  .my_nav {
    >>> .van-nav-bar__left .van-icon-arrow-left.van-icon {
      font-size: .3rem;
    }
    >>> .van-nav-bar__title {
      font-size: .26rem;
    }
  }
  .main {
    .phone {
      margin-top: 0.075rem;
        >>> .van-field__label {
          width: auto;
        }

    }

    .error-img >>> .van-empty__image {
      width: 1.5rem;
      height: 1.5rem;
    }

    .form {
      margin: 0.15rem 0 .975rem;
      background-color: #fff;
      .category-title-label{
        font-size: 0.37333rem;
        padding: 0.2rem 0;
        margin: 0 0.42667rem;
        color: #646566;
        text-align: left;
        word-wrap: break-word;
        font-weight: 400;

      }
      .category-title{
        font-size: 14px;
        padding: 0.2rem 0;
        margin: 0 0.32rem;
        border-bottom: 0.02rem solid #ebedf0;
        &::before{
          content: "";
          display: inline-block;
          width: 3px;
          height: 14px;
          margin-right: 10px;
          vertical-align: middle;
          background: #48a66e;
        }
      }
      .field-left-icon{
          width: 0.375rem;
          font-size: 0.28rem;
      }
      .send {
        color: #39bb8f;
      }

      .submit {
        background: #39bb8f;;
        color: white;
        border-radius: 5px;
      }
    }

  }
  .center-find-user{
    position: fixed;
    width: 100%;
    top: 50%;
    height: 180px;
    margin-top: -100px;
    text-align: center;
    .icon-find-user-by-tel{
      width: 2rem;
      height: 2rem;
      color: #fff;
      background-color: #48a66e;
      font-size: 1.4rem;
      border-radius: 50%;
      line-height: 2rem;
    }
    .find-input{
      width: 80%;
      margin: 20px auto;
    }

  }
</style>
