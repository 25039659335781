import axios from '@/utils/axios';
const baseURL = '/api'
// 物业报修

// 社区 小区 列表
export const levelList = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/village/tree',
    method: 'get',
    params
  });
}; 
// 报修反馈类型
export const opinionType = (params) => {
    return axios({
      baseURL,
      url: '/blade-system/dict/dictionary?code=repair_user',
      method: 'get',
      params
    });
  };
// 新增物业报修
export const repairSave = (data) => {
  return axios({
    baseURL,
    url: '/repair/save',
    method: 'post',
    data
  });
};
// 社区 小区 列表
export const levelLists = (params) => {
  return axios({
    baseURL,
    url: '/app/open/dept/levelList',
    method: 'get',
    params
  });
}; 

// 维修人员全部待维修列表
export const workList = (params) => {
  return axios({
    baseURL,
    url: '/repair/needRepairList',
    method: 'get',
    params
  });
}; 

// 我的待维修列表
export const getMyWorkList = (params) => {
  return axios({
    baseURL,
    url: '/repair/repairListMe',
    method: 'get',
    params
  });
}; 

// 居民报修列表
export const myRepairList = (params) => {
  return axios({
    baseURL,
    url: '/repair/page',
    method: 'get',
    params
  });
}; 

// 维修人员列表
export const workerList = (params) => {
  return axios({
    baseURL,
    url: '/repairUser/repairUserByType',
    method: 'get',
    params
  });
}; 

// 维修详情
export const repairDetail = (params) => {
  return axios({
    baseURL,
    url: '/repair/detail',
    method: 'get',
    params
  });
}; 

// 公共报修-报修项目
//  公共报修 个人报修写死
// export const repairType = (params) => {
//   return axios({
//     baseURL,
//     url: "/blade-system/dict/getDataByCode?code=repair_type",
//     method: "get",
//     params,
//   });
// };

// // 公共报修-报修项目
// 改用字典
// export const repairProject = (params) => {
//   return axios({
//     baseURL,
//     url: "/blade-system/dict/getBelowNodeByPid?code=repair_type",
//     method: "get",
//     params,
//   });
// };

// 撤销
export const repairRemove = (params) => {
  return axios({
    baseURL,
    url: "/repair/cancel",
    method: "post",
    params,
  });
};

// 报修记录
export const repairList = (params) => {
  return axios({
    baseURL,
    url: "/repair/page",
    method: "get",
    params,
  });
};

// 判断当前用户是不是维修员
export const isAuth = (userId) => {
  return axios({
    baseURL,
    url: '/app/user/userSpecialByUserId?userId=' + userId,
    method: 'post',
  });
};

// // 维修员开始/结束维修
// export const startStatus = (data) => {
//   return axios({
//     baseURL,
//     url: '/repair/accept',
//     method: 'post',
//     data
//   });
// };

// 维修员开始/结束维修
export const updateStatus = (data) => {
  return axios({
    baseURL,
    url: '/repair/accept',
    method: 'post',
    data
  });
};

// 居民维修更新状态 是否解决
// 已解决 评分
// 未解决 图片 原因
export const userUpdateStatus = (data) => {
  return axios({
    baseURL,
    url: '/repair/solveTwo',
    method: 'post',
    data
  });
};

// 维修评价
export const setEvaluate = (data) => {
  return axios({
    baseURL,
    url: '/repair/evaluate',
    method: 'post',
    data
  });
};