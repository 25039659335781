import axios from '@/utils/axios';
const baseURL = '/api'
// 访客预约

// 新增访客预约
export const visitorSave = (data) => {
  return axios({
    baseURL,
    url: '/visitor/record/save',
    method: 'post',
    data
  });
};